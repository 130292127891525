<template>
  <div class="detail positionr" v-loading="loading">
    <!-- 面包屑导航区 -->
    <el-row>
      <el-col
        ><el-breadcrumb
          ><el-breadcrumb-item class="pointer" :to="{ path: '/carousel' }"
            >导航</el-breadcrumb-item
          >
          <el-breadcrumb-item>详情</el-breadcrumb-item>
        </el-breadcrumb></el-col
      >
    </el-row>
    <!-- 内容主体 -->
    <div class="bgwhite bort">
      <el-row>
        <!-- tabbar -->
        <el-col :span="3">
          <div id="navigation">
            <ul>
              <li
                class="menu-item text-cut"
                v-for="(item, i) in tabs"
                :key="item"
                :class="seeThis == i ? 'active' : ''"
              >
                <a href="javascript:void(0)" @click="goAnchor(i)">{{ item }}</a>
              </li>
            </ul>
          </div>
        </el-col>
        <!-- tabbar对应内容 -->
        <el-col :span="21">
          <div class="menu-content">
            <!-- 基本信息 -->
            <div class="p1" id="a1">
              <div class="header-search">
                <div class="title">
                  <el-row>
                    <el-col :span="12">
                      <span class="line"></span>
                      基本信息
                    </el-col>
                    <el-col :span="12">
                      <div class="flex align-center justify-end h50">
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="保存基本信息和扩展属性"
                          placement="bottom"
                        >
                          <el-button
                            type="primary"
                            size="small"
                            @click="submitForm('carousel')"
                            >保存</el-button
                          >
                        </el-tooltip>
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="返回列表"
                          placement="bottom"
                        >
                          <el-button @click="goIndex()" size="small"
                            >返回</el-button
                          >
                        </el-tooltip>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="header-input">
                <el-form
                  :model="carousel"
                  ref="carousel"
                  label-width="150px"
                  class="carousel"
                  size="mini"
                >

                  <el-row>
                                      <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="类型选择" prop="courseCode">

                        <div class="block">
                          <el-cascader
                          style="width:50%"
                            v-model="value"
                            :options="options"
                            @change="handleChange"
                          ></el-cascader>
                        </div>
                      </el-form-item>
                    </el-col>
                    <el-col
                      :sm="24"
                      :md="24"
                      :lg="12"
                      style="margin-left: -7.5%"
                    >
                      <el-form-item label="排序" prop="seq">
                        <el-input v-model="carousel.seq" style="width:50%"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>

                  <el-row style="margin-left:82px">
                    <el-col :sm="24" :md="24" :lg="12" style="display: none;">
                      <el-form-item label="id" prop="id">
                        <el-input v-model="carousel.id"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-breadcrumb-item>请上传轮播图</el-breadcrumb-item>
                    <el-upload
                      class="avatar-uploader"
                      :action="$uploadURL"
                      :show-file-list="false"
                      :on-success="handleAvatarSuccess"
                      :before-upload="beforeAvatarUpload"
                    >
                      <img
                        v-if="carousel.url"
                        :src="carousel.url"
                        class="avatar"
                      />
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                  </el-row>
                  <el-col :span="24" v-if="HtmlIsShow" style="margin-top:40px">
                      <el-form-item label="内容" prop="content">
                        <quill-editor
                          ref="text"
                          v-model="carousel.content"
                            class="myQuillEditor yunmu-ql-editor"
                        />
                      </el-form-item>
                    </el-col>
                </el-form>
              </div>
            </div>
            <!--  多功能 -->
            <!-- <CarouselFunction></CarouselFunction> -->

            <!-- 扩展-->
            <!-- <CarouselExtends></CarouselExtends> -->
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
// import CarouselFunction from '@/views/carousel/CarouselFunction.vue'
// import CarouselExtends from '@/views/carousel/CarouselExtends.vue'
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    //   CarouselFunction,
    //   CarouselExtends
    quillEditor
  },
  data() {
    return {
      //三级分类
      value: [],
      options: [
        {
          value: "1",
          label: "课程",
          children: [],
        },
        {
          value: "2",
          label: "自定义",
        },
      ],
      //类型选择是自定义时，展示富文本
      activityContent: "",
      HtmlIsShow:false,

      // 当前页数
      currPage: 1,
      pageSize: 10,
      seeThis: 0,
      loading: true,
      id: "",
      content:"",
      courseCode:"",
      tabs: ["基本信息"],
      carousel: {
        chapterCode:"",
        id: "",
        createBy: "",
        createTime: "",
        updateBy: "",
        updateTime: "",
        pathType: "",
        pathUrl: "",
        content: "",
        url: "",
        seq: "",
        content:"",
      },
      allCourseList: [],
      pathList: [],
      featuresList: [],
    };
  },
  methods: {
    //三级分类
    handleChange(value) {
      console.log("看看三级分类选择了什么", value);
      console.log("===>", this.courseList);
      if(value==2){
        this.HtmlIsShow=true;
      }else{
        this.HtmlIsShow=false;
        this.pathType=value[0];
      }
       this.carousel.pathType=value[0];
      this.carousel.chapterCode=value[3]

    },
    //给三级分类课程赋值
    setTypeList() {
      // console.log("--->列表", this.options);
      let i = 0;
      let arr1 = [];
      let arr2=[];
      let arr3 = [];
      if (this.allCourseList.length > 0) {
        for (i; i < this.allCourseList.length; i++) {
            arr1.push({ label: "" ,value:"",children:[]});
            arr1[i].label = this.allCourseList[i].courseTypeName;
            arr1[i].value = this.allCourseList[i].courseTypeCode;

            for (let j = 0; j < this.allCourseList[i].courseList.length; j++) {
                arr2.push({ label: "" ,value:"",children:[]});
                arr2[j].label=this.allCourseList[i].courseList[j].courseName;
                arr2[j].value=this.allCourseList[i].courseList[j].courseCode
                for (let k = 0;k <this.allCourseList[i].courseList[j].courseChapterList.length;k++)
                {
                  arr3.push({ label: "" ,value:""});
                  arr3[k].label =this.allCourseList[i].courseList[j].courseChapterList[k].chapterName;
                  arr3[k].value =this.allCourseList[i].courseList[j].courseChapterList[k].chapterCode;
                }
                // arr2=arr2.concat(arr3);
                arr2[j].children=arr3
                arr3=[];
            }
            arr1[i].children=arr2;
            arr2=[];
          this.options[0].children = arr1;
        }
      }
      // console.log("arr1", arr1);
      // console.log("arr2", arr2);
      // console.log("--->options");
    },

    //获取课程列表
    getCourseList() {
      let that = this;
      that.$http.post("/courseType/queryAllList", {}).then(function (response) {
        if (response.data.code == 200) {
          that.allCourseList = response.data.data;
          console.log("--->queryAllList", that.allCourseList);
          that.setTypeList();
        }
      });
    },

    //获取特征列表
    queryFeaturesList(pathCode) {
      let that = this;
      that.featuresCode = pathCode;
      if (pathCode == 1) {
        that.$http.post("/syllabus/queryList", {}).then(function (response) {
          if (response.data.code == 200) {
            var useList = [];
            let tempList = response.data.data;
            tempList.forEach((item) => {
              var features = {};
              features.name = item.syllabusName;
              features.code = item.syllabusCode;
              useList.push(features);
            });
            that.featuresList = useList;
            console.log("---->featuresList", that.featuresList);
          }
        });
      } else if (pathCode == 2) {
        that.$http.post("/activity/queryList", {}).then(function (response) {
          if (response.data.code == 200) {
            var useList = [];
            let tempList = response.data.data;
            tempList.forEach((item) => {
              var features = {};
              features.name = item.activityName;
              features.code = item.activityCode;
              useList.push(features);
            });
            that.featuresList = useList;
          }
        });
      } else {
        that.$http.post("/audio/queryList", {}).then(function (response) {
          if (response.data.code == 200) {
            var useList = [];
            let tempList = response.data.data;
            tempList.forEach((item) => {
              var features = {};
              features.name = item.audioName;
              features.code = item.audioCode;
              useList.push(features);
            });
            that.featuresList = useList;
          }
        });
      }
    },
    handleAvatarSuccess(res, file) {
      this.carousel.url = file.response.data.url;
      console.log("this.course.url", this.carousel.url);
    },
    beforeAvatarUpload(file) {
      //  const imgFile = ['png', 'jpg', 'jpeg', 'bmp', 'gif']
      // const isJPG = file.type === "image/jpeg" ||"image/png"||"image/jpg";
      // const isLt2M = file.size / 1024 / 1024 < 2;
      // if (!isJPG) {
      //   this.$message.error("上传头像图片只能是 JPG,png,jpeg 格式!");
      // }
      // if (!isLt2M) {
      //   this.$message.error("上传头像图片大小不能超过 2MB!");
      // }
      // return isJPG && isLt2M;
    },
    goIndex() {
      this.$router.push({
        path: "/carousel",
        query: { currPage: this.currPage, pageSize: this.pageSize },
      });
    },
    submitForm(formName) {
      var that = this;
      var loginName1 = localStorage.getItem("loginName");
      var loginNameActual = JSON.parse(loginName1).value;
      this.carousel.createBy = loginNameActual;
      // this.carousel.pathType = "1";
      // this.carousel.courseCode = "202205011292622";
      console.log("carousel:",that.carousel)
      that.$http.post("/carousel/save", that.carousel).then((response) => {
        if (response.data.code == 200) {
          that.$notify.success({
            title: "提示",
            message: "保存成功",
            showClose: true,
          });
          this.goIndex();
        } else {
          that.$notify.error({
            title: "提示",
            message: response.data.message,
            showClose: true,
          });
        }
      });
      // .catch(function (error) {
      //   that.$notify.info({
      //     title: "提示",
      //     message: "保存失败",
      //     showClose: true,
      //   });
      // });
    },
    loadBaseInfo() {
      let that = this;
      if (this.id != undefined && this.id != "") {
        that.$http
          .post("/carousel/queryOne", { id: this.id })
          .then(function (response) {
            if (response.data.code == 200) {
              that.total = response.data.data.count;
              that.carousel = response.data.data;
            }
          });
      }
    },
    goAnchor(index) {
      // 也可以用scrollIntoView方法， 但由于这里头部设置了固定定位，所以用了这种方法
      // document.querySelector('#anchor'+index).scrollIntoView()
      this.seeThis = index;
      var i = index + 1;
      var anchor = this.$el.querySelector("#a" + i);
      this.$nextTick(() => {
        this.$el.querySelector(".menu-content").scrollTop = anchor.offsetTop;
      });
    },
    handleScroll() {
      //console.log("滚动了");
      var scrollTop = this.$el.querySelector(".menu-content").scrollTop;
      var scrollHeight = this.$el.querySelector(".menu-content").scrollHeight;
      var height = this.$el.querySelector(".menu-content").offsetHeight;
      var anchorOffset1 = this.$el.querySelector("#a1").offsetTop;
      var anchorOffset2 = this.$el.querySelector("#a2").offsetTop;
      var anchorOffset3 = this.$el.querySelector("#a3").offsetTop;

      if (scrollTop > anchorOffset1 && scrollTop < anchorOffset2) {
        this.seeThis = 0;
      }
      if (scrollTop > anchorOffset2 && scrollTop < anchorOffset3) {
        this.seeThis = 1;
      }
      if (scrollTop > anchorOffset3 && scrollTop + height > scrollHeight) {
        this.seeThis = 3;
      }

      if (scrollTop + height > scrollHeight) {
        this.seeThis = 3;
      }
    },
  },
  created() {
    if (this.$route.query.id != undefined && this.$route.query.id != "") {
      this.id = this.$route.query.id;
    }
    this.currPage = this.$route.query.currPage;
    this.pageSize = this.$route.query.pageSize;
    this.loadBaseInfo();
    this.getCourseList();
  },
  mounted() {
    var that = this;
    this.$el.querySelector(".menu-content").onscroll = () => {
      that.handleScroll();
    }; // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    that.loading = false;
  },
};
</script>

<style lang="less" scope>
.h50 {
  height: 50px;
}
.detail {
  .el-breadcrumb__inner {
    color: #2f91df;
  }
  .el-row {
    #navigation {
      background-color: #f6f7fb;
      font-size: 14px;
      height: calc(100vh - 110px);
      margin-top: -14px;
      li {
        height: 50px;
        line-height: 50px;
        a {
          display: block;
          color: #324059;
          padding-left: 20px;
          background-color: transparent;
        }
      }
      li.active,
      li:hover {
        background-color: #fff;
      }
    }
    .menu-content {
      height: calc(100vh - 110px);
      overflow-y: auto;
      padding-left: 10px;
      padding-right: 5px;
      .p1 {
        margin-bottom: 20px;
      }
      .header-search {
        .title {
          height: 50px;
          line-height: 49px;
          font-size: 15px;
          color: #2f405b;
          padding-left: 10px;
          border-bottom: 1px solid #e3eaf0;
          margin-bottom: 10px;
          .line {
            display: inline-block;
            width: 3px;
            height: 15px;
            background-color: #2f91df;
            margin-right: 5px;
            border-radius: 2px;
            margin-bottom: -2px;
          }
          .el-input {
            width: 247px;
          }
          .el-input .el-input__inner {
            width: 217px;
          }
        }
      }
      .header-input {
        font-size: 13px;
      }
      .model-mask {
        .el-form {
          .el-form-item {
            margin-bottom: 0;
          }
        }
        .el-table {
          margin-top: 10px;
        }
      }
    }
  }
  #selectorOrgDialog .el-dialog {
    width: 600px !important;
  }
  #selectorOrgDialog .selectedUl {
    height: 350px;
    max-height: 350px;
  }
  #selectorOrgDialog .org-selected .selectedUl-box {
    height: 320px !important;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
</style>
